<template>
  <div two-factor-signin>
    <div class="box is-12">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div v-if="primaryAuth && isPrimary === true" class="form-grid">
                <div class="column is-12">
                  <label class="label" v-if="primaryAuth.authenticationType !== 'AuthenticatorCode'"
                    >Click the "Send Code" button to send a code to:<Info
                      title="Send Code to Primary Authentication"
                      information="Sends an authentication token to your primary additional authentication under your user settings profile. This token will then be entered when prompted as additional authentication to your password."
                  /></label>
                  <label
                    class="label"
                    v-else-if="primaryAuth.authenticationType === 'AuthenticatorCode'"
                    >Read the code off of your registered authenticator app.<Info
                      title="Read Code"
                      information="At the additional authentication prompt a token was sent to your primary additional authentication preference. Type the displayed token into the additional authentication token prompt."
                  /></label>
                  <label
                    class="pb-5 label"
                    v-if="primaryAuth.smsPhoneNumber && primaryAuth.smsPhoneNumber !== undefined"
                    >{{ `Phone Number: ${primaryAuth.smsPhoneNumber}` }}</label
                  >
                  <label
                    class="pb-5 label"
                    v-else-if="primaryAuth.emailAddress && primaryAuth.emailAddress !== undefined"
                    >{{ `Email Address: ${primaryAuth.emailAddress}` }}</label
                  >
                  <span v-if="primaryAuth.authenticationType !== 'AuthenticatorCode'">
                    <button
                      @click.prevent="sendingCode(primaryAuth.authenticationID, 'Primary')"
                      class="button is-light"
                    >
                      Send Code
                    </button></span
                  >
                </div>
                <div class="pt-5 column is-12">
                  <label class="label"
                    >Enter Received Primary Authentication Code<Info
                      title="Enter Received Primary Authentication Code"
                      information="At the additional authentication prompt a token was sent to your primary additional authentication preference. Type the displayed token into the additional authentication token prompt."
                  /></label>
                  <valid-input
                    name="SendCodePrimary"
                    maxlength="20"
                    type="text"
                    vid="sendCodePrimary"
                    placeholder="Enter Received Primary Authentication Code"
                    aria-label="Enter Received Primary Authentication Code"
                    rules="required:max:20"
                    v-model="formData.sendCodePrimary"
                  />
                </div>
              </div>
              <div v-else-if="secondaryAuth" class="form-grid">
                <div class="column is-12">
                  <label class="label"
                    >Click the "Send Code" button to send a code to:<Info
                      title="Send Code to Secondary Authentication"
                      information="Sends an authentication token to your secondary additional authentication under your user settings profile. This token will then be entered when prompted as additional authentication to your password."
                  /></label>
                  <label
                    class="pb-5 label"
                    v-if="
                      secondaryAuth.smsPhoneNumber && secondaryAuth.smsPhoneNumber !== undefined
                    "
                    >{{ `Phone Number: ${secondaryAuth.smsPhoneNumber}` }}</label
                  >
                  <label
                    class="pb-5 label"
                    v-else-if="
                      secondaryAuth.emailAddress && secondaryAuth.emailAddress !== undefined
                    "
                    >{{ `Email Address: ${secondaryAuth.emailAddress}` }}</label
                  >
                  <button
                    @click.prevent="sendingCode(secondaryAuth.authenticationID, 'Secondary')"
                    class="button is-light"
                  >
                    Send Code
                  </button>
                </div>
                <div class="pt-5 column is-12">
                  <label class="label"
                    >Enter Received Secondary Authentication Code<Info
                      title="Enter Received Secondary Authentication Code"
                      information="At the additional authentication prompt a token was sent to your secondary additional authentication preference. Type the displayed token into the additional authentication token prompt."
                  /></label>
                  <valid-input
                    name="SendCodeSecondary"
                    maxlength="20"
                    type="text"
                    vid="sendCodeSecondary"
                    placeholder="Enter Received Secondary Authentication Code"
                    aria-label="Enter Received Secondary Authentication Code"
                    rules="required:max:20"
                    v-model="formData.sendCodeSecondary"
                  />
                </div>
              </div>
              <div>
                <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                  Submit Code</button
                >&nbsp;
                <b-button
                  type="button"
                  @click.prevent="switchAuthTypes(currentAuthType)"
                  class="button is-primary is-rounded"
                  :disabled="loading || !secondaryAuth"
                  >{{
                    `Use ${currentAuthType === 'Primary' ? 'Secondary' : 'Primary'} Method`
                  }} </b-button
                >&nbsp;
                <b-button
                  type="button"
                  class="button is-primary is-rounded"
                  @click.prevent="closeModal()"
                  :disabled="loading"
                  >Close
                </b-button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Info from '@/components/icons/Info'
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
import { notifyError, notifyMessage } from '@/services/notify'
import { mapActions } from 'vuex'

// components
import ValidInput from '@/components/inputs/ValidInput'
import $ from 'jquery'

import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  sendCodePrimary: '',
  sendCodeSecondary: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  props: {
    requiresTwoFactor: Array
  },
  data() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },

      primaryAuth: null,
      secondaryAuth: null,

      currentAuthType: 'Primary',

      isPrimary: true,
      toggle: false,
      isDebug: true
    }
  },

  computed: {},

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    Info
  },

  async mounted() {
    this.reload()
  },

  methods: {
    ...mapActions('user', ['twoFactorLogin']),

    async reload() {
      await this.loadTwoFactorTypes()
    },

    closeModal() {
      this.$emit('update:toggle', (this.toggle = false))

      // auto-close modal
      $('#clickclose').click()
    },

    async loadTwoFactorTypes() {
      if (
        this.requiresTwoFactor &&
        this.requiresTwoFactor != undefined &&
        this.requiresTwoFactor.length > 0
      ) {
        let counter = 1
        Array.from(this.requiresTwoFactor).forEach(element => {
          if (element) {
            if (counter === 1) {
              this.primaryAuth = element

              if (this.isDebug === true)
                console.debug('loadTwoFactorTypes()=' + JSON.stringify(this.primaryAuth))
            } else {
              this.secondaryAuth = element
              this.curcurrentAuthType = 'Secondary'
            }

            counter++
          }
        })
      }
    },

    switchAuthTypes(fromAuthType) {
      if (fromAuthType === 'Secondary') {
        this.isPrimary = true
        this.currentAuthType = 'Primary'
      } else {
        this.isPrimary = false
        this.currentAuthType = 'Secondary'
      }
    },

    async sendingCode(authenticationID, authType) {
      if (!authenticationID && authenticationID <= 0) {
        notifyError('There was a problem sending the authentication code.')
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        await twoFactorAuthentication.dispatch('sendCode', {
          authenticationID: authenticationID,
          done: () => {
            notifyMessage(`Successfully sent your ${authType} authentication code.`)
            loadingComponent.close()
          }
        })
      } catch (e) {
        notifyError('There was an issue adding your primary multi-factor setup.')
      }

      loadingComponent.close()
    },

    async onFormSubmit() {
      if (this.isDebug === true) console.debug('in onFormSubmit()...')

      if (!this.formData && !this.primaryAuth) {
        notifyError('There was a problem validating the authentication code.')
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        await twoFactorAuthentication.dispatch('validateAuthenticationCode', {
          authenticationID: this.isPrimary
            ? this.primaryAuth.authenticationID
            : this.secondaryAuth.authenticationID,
          code: this.isPrimary ? this.formData.sendCodePrimary : this.formData.sendCodeSecondary,
          done: ({ details }) => {
            if (details) {
              if (this.isDebug === true)
                console.debug('validateAuthenticationCode=' + JSON.stringify(details))

              const { token, refreshToken, expiration } = details
              this.twoFactorLogin({ token, refreshToken, expiration }).then(() => {
                notifyMessage(`Successfully authenticated your multi-factor code.`)
              })

              loadingComponent.close()
            }
          }
        })
      } catch (e) {
        notifyError('There was an issue validating your multi-factor code.')
      }

      loadingComponent.close()
    }
  }
}
</script>
